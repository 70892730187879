body {
    margin: 0;
    font-family: "myriad-pro", sans-serif;
    background-color: #F6F6F6;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Courier New', source-code-pro, Menlo, Monaco, Consolas, monospace;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.wrap-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.wrap-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.main-customer {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    justify-content: left;
    overflow: hidden;
}

.customer-subdiv-smallbox {
    width: 50%;
    border: 1px;
    border-color: black;
    border-style: solid;
    margin: 10px;
}

.customer-subdiv-largebox {
    display: flex;
    border: 1px;
    border-color: black;
    border-style: solid;
    margin: 10px;
}

.customer-tabs-largebox {
    border: 1px;
    margin: 10px;
}

.customer-tab-largebox-head {
    display: flex;
    height: auto;
    border: 1px;
    margin: 1px;
}

.customer-tab-largebox {
    display: flex;
    border: 1px;
    margin: 1px;
}

.main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.c-main {
    cursor: default;
}

.main-grids {
    width: 100%;
    margin: 0 auto;
    justify-content: left;
    overflow: hidden;
}

.audit-grids {
    width: 100%;  
    margin: 0 auto;
    justify-content: left;
    overflow: visible;    
    overflow-y: visible;
    
}

.pagination-display {
    width: 100%;
    margin: 0 auto;
    margin-top: 5px;
    justify-content: left;
    overflow: hidden;
}

.match-display {
    display: flex;
    overflow-x: hidden;
    padding-top: 1rem;
}

.source-display {
    flex: 50%;
    top: 10px;
}

.target-display {
    flex: 50%;
    top: 10px;
    left: 0;
}

.main-search {
    width: 350px;
    margin: 4 auto;
    justify-content: left;
}

.main-header {
    width: 100%;
    margin: 0 auto;
    justify-content: right;
    height: 20px;
    display: flex;
    background-color: #FFFFFF;
}

.main-form {
    width: 15%;
}

.main-up-menu {
    width: 100%;
    align-content: flex-end;
    text-align: right;
    margin-right: 10px;
}

.main-form--inline {
    padding-left: 4px;
    padding-top: 4px;
    padding-right: 4px;
}

.main-results {
    width: 90%;
}

.o-label {
    font-size: 1rem;
    display: block;
    color: #575A61;
    text-align: left;
    margin-top: 1rem;
}

.o-label.title {
    font-size: 24px;
    font-weight: 800;
}

.o-input {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    color: #575A61;
    border: 0.1rem solid #E9E9E9;
    border-radius: 0.2rem;
    padding: 0.5rem 0.5rem;
    width: 93%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    line-height: 20px;
    padding: 11px 15px 11px 15px;
    font-family: myriad-pro, sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #303030;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    outline: 0;
    -webkit-transition: background .3s cubic-bezier(.25, .46, .45, .94), border .3s cubic-bezier(.25, .46, .45, .94);
    transition: background .3s cubic-bezier(.25, .46, .45, .94), border .3s cubic-bezier(.25, .46, .45, .94)
}

.o-input:focus,
[data-whatinput="keyboard"] .o-input:focus {
    outline: none;
    border-radius: 0.2rem;
    box-shadow: 0 0 0 0.2rem #99C6F3;
}

.o-input.error {
    background-color: rgba(255, 42, 77, 0.05);
    border-color: rgba(255, 42, 77, 0.3);
}

.basic-multi-select_error__value-container {
    background-color: rgba(255, 42, 77, 0.05);
    border-color: rgba(255, 42, 77, 0.3);
}

.row-card-paragraph {
    display: block;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0;
    margin-right: 0;
}

.c-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: opacity .1s ease-in-out;
}


/* =========================================================================  */


/* Object: Nav Menu */


/* =========================================================================  */

.nav {
    font-size: 20px;
    font-weight: 700;
    font-family: "myriad-pro", sans-serif;
    cursor: pointer;
}

.nav.mainnav {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 12px 0;
}

.navworkflow {
    width: fit-content;
    padding-top: 22px;
}


/* =========================================================================  */


/* Object: icon */


/* =========================================================================  */

.o-icon {
    fill: currentColor;
    background-color: transparent;
    display: block;
}

.o-icon:hover {
    background-color: transparent;
}

.o-icon--table {
    width: 1.1rem;
    height: 1.4rem;
}

.o-icon--dropdown {
    width: 0.9rem;
    height: 1.6rem;
}

.c-form__field {
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-top: 5px;
}

.entity-card {
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.08s ease-in-out;
    color: #575A61;
    background-color: #ffffff;
    border-radius: 0.2rem;
    margin-bottom: 1rem;
    position: relative;
    -webkit-animation: fadeIn .3s ease-in-out forwards;
    animation: fadeIn .3s ease-in-out forwards;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border-style: solid;
    border-width: thin;
    border-color: #FFFFFF;
}

.entity-card:hover {
    cursor: pointer;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.entity-card__color-coding-- {
    background-color: #888D95;
}

.entity-card--outline {
    padding: 0rem 1.4rem 3rem 1rem;
    cursor: auto;
    margin-bottom: 0;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
}

.entity-card--alertinfo {
    box-shadow: inset 0 0 0 0.1rem #E9E9E9;
    padding: 0rem 1.4rem 3rem 1rem;
    cursor: auto;
    border-radius: 0.4rem;
    margin-bottom: 0;
}

.entity-card--data {
    padding: -0.1rem 1.4rem 3rem 2rem;
    cursor: auto;
    border-radius: 0.4rem;
    margin-top: 0.4rem;
    font-size: 1.2rem;
    transition: background-color 0.08s ease-in-out;
    color: #575A61;
    background-color: #ffffff;
    -webkit-animation: fadeIn .3s ease-in-out forwards;
    animation: fadeIn .3s ease-in-out forwards;
    display: -webkit-flex;
    display: -ms-flexbox;
    overflow: auto;
    border-bottom: 1px solid #D4D4D4;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.15);
}

.entity-card__icon {
    color: #0D0DC7;
    position: absolute;
    left: 2.8rem;
    top: 2.7rem;
}

.entity-card--outline .entity-card__data {
    padding: 0;
    line-height: initial;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.entity-card--outline .entity-card__data:before,
.entity-card--outline .entity-card__data:after {
    content: none;
}

.entity-card--outline .entity-card__subject-wrapper {
    padding-right: 2rem;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entity-card--outline .entity-card__info-wrapper {
    padding-right: 2rem;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    height: 12rem;
}

.entity-card__header {
    font-size: 24px;
    font-weight: bold;
    color: rgb(42, 42, 42);
    margin-bottom: 7px;
    text-transform: capitalize;
}

.entity-card__label {
    font-size: 24px;
    color: rgb(42, 42, 42);
    margin-top: 0px;
    margin-left: 0px;
    text-transform: capitalize;
}

.entity-card--outline .entity-card__subject {
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.entity-card--outline .entity-card__subject-extra {
    font-size: 1.1rem;
    color: #888D95;
    margin: 0;
}

.entity-card__subject-extra {
    font-size: 1.1rem;
    color: #888D95;
    margin: 10px;
}

.entity-card--outline .entity-card__details-wrapper {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    text-align: right;
}

.entity-card--outline .entity-card__more-btn {
    top: 2.5rem;
    opacity: 1;
}

.entity-card--outline .entity-card__badges {
    position: absolute;
    right: 3rem;
    top: 1rem;
}

.entity-card--outline .entity-card__date {
    position: absolute;
    right: 1.1rem;
    bottom: 1.5rem;
    color: #888D95;
    font-size: 1.3rem;
}

.entity-card__color-coding {
    content: '';
    width: .6rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #F6F6F6;
    z-index: 1;
    border-radius: 2px 0 0 2px;
    will-change: transform;
    margin-left: 0;
}

.entity-card__color-coding--low {
    background-color: #ABE1BC;
}

.entity-card__color-coding--guarded {
    background-color: #2AAFBF;
}

.entity-card__color-coding--elevated {
    background-color: #F5E276;
}

.entity-card__color-coding--high {
    background-color: #FBA504;
}

.entity-card__color-coding--severe {
    background-color: #FF5D3C;
}

#color-coding-low {
    stroke: #ABE1BC;
}

#color-coding-guarded {
    stroke: #2AAFBF;
}

#color-coding-elevated {
    stroke: #2AAFBF;
}

#color-coding-high {
    stroke: #FBA504;
}

#color-coding-severe {
    stroke: #FF5D3C;
}

.invisibar {
    opacity: 0;
}


/* =========================================================================  */


/* Horizontal cards inner wrapper */


/* =========================================================================  */

.entity-card__data {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    line-height: 1rem;
    padding: 1rem 0;
    width: 100%;
}

.entity-card__comment {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    line-height: 1rem;
    padding: 0.2rem 0;
    width: 100%;
    cursor: default;
}

.entity-card__data:before,
.entity-card__data:after {
    content: " ";
    display: table;
}

.entity-card__data:after {
    clear: both;
}

.entity-card__data-value {
    float: left;
    margin: 0;
    width: calc(100% / 5);
    padding-left: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.d-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.d-list--small {
    list-style-type: none;
    padding: 0;
}

.d-list--borders>.d-list__item:nth-child(n+4) {
    margin-top: 1rem;
    border-top: 0.1rem solid #E9E9E9;
    padding-top: 1rem;
}

.d-list--spacing>.d-list__item:nth-child(n+4) {
    margin-top: 1rem;
}

.d-list--extra-spacing>.d-list__item:nth-child(n+4) {
    margin-top: 2rem;
}

.d-list--inline {
    display: inline-block;
}

.d-list--inline:before,
.d-list--inline:after {
    content: " ";
    display: table;
}

.d-list--inline:after {
    clear: both;
}

.d-list--inline>.d-list__item {
    float: left;
}

.d-list--inline.d-list--spacing>.d-list__item:nth-child(n+2) {
    margin-top: 0;
    margin-left: 1rem;
}

.d-list--inline.d-list--extra-spacing>.d-list__item:nth-child(n+2) {
    margin-top: 0;
    margin-left: 2rem;
}

.d-list--inline.d-list--no-wrap {
    white-space: nowrap;
}

.d-list--inline.d-list--no-wrap>.d-list__item {
    display: inline-block;
}

.d-list--will-hide {
    transition: opacity .05s ease-in-out;
    opacity: 0;
}

.d-list--will-hide.is-visible {
    opacity: 1;
}

.d-list--reorderable {
    position: relative;
}

.d-list--reorderable>.d-list__item {
    width: 100%;
}

.d-list--reorderable .gu-hide {
    display: none !important;
}

.d-list--reorderable .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.d-list--reorderable .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
}

.d-list--reorderable .gu-hide {
    display: none !important;
}

.d-list--reorderable .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.d-list--reorderable .gu-transit {
    position: relative;
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}


/* =========================================================================  */


/* Popover */


/* =========================================================================  */

.d-list__item--popover-padding {
    padding: 1rem 2rem;
    position: relative;
}

.d-list__item--popover-padding:hover {
    background-color: #F6F6F6;
    cursor: pointer;
}

.d-list__item--popover-padding:hover:after {
    content: '';
    display: block;
    width: 100%;
    height: 0.1rem;
    background-color: #F6F6F6;
    position: absolute;
    left: 0;
    bottom: -0.1rem;
}

.d-list__item--popover-padding.is-unread,
.d-list__item--popover-padding.is-unread:hover,
.d-list__item--popover-padding.is-unread:hover:after {
    background-color: #ECEDFC;
}

.d-list__item--popover-padding:nth-child(n+2):before {
    content: '';
    display: block;
    width: calc(100% - 4rem);
    height: 0.1rem;
    background-color: #E9E9E9;
    position: absolute;
    left: 2rem;
    top: 0;
}

.d-list--padded {
    padding: 0 2rem 2rem;
}

.d-field--inline {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    line-height: 1rem;
    margin-bottom: 0.1rem;
}

.d-field--inline .d-field__value {
    margin: 0;
    line-height: inherit;
}

.d-field--inline.is-active {
    background-color: #DDDDF8;
    color: #0D0DC7;
}

.u-attention-required.is-active {
    background-color: #ffc3c0;
}

.d-field--nomargin {
    font-weight: 400;
    line-height: 1;
    padding: 0.3rem;
    background-color: #F6F6F6;
    border-radius: 0.2rem;
    position: relative;
    margin: 0.2rem;
    margin-bottom: 0.2rem;
}

.d-field--search {
    font-weight: 400;
    line-height: 1;
    padding: 0.1rem;
    background-color: #F6F6F6;
    border-radius: 0.2rem;
    position: relative;
    margin: 0.2rem;
    margin-bottom: 0.3rem;
}

.form.errors {
    background-color: rgba(255, 42, 77, 0.15);
    color: #ff2a4d;
    position: relative;
    padding: 15px 30px;
    margin-top: 20px;
    border-radius: 10px;
}

.form-errors.p {
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 0 30px
}