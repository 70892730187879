.notification {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    z-index: 100;
}

.o-icon--cross {
    width: 1rem;
    height: 1rem;
    padding-top: 5px;
}

.c-notification-card {
    transition: opacity .08s ease-in-out, -webkit-transform .08s ease-in-out;
    transition: opacity .08s ease-in-out, transform .08s ease-in-out;
    transition: opacity .08s ease-in-out, transform .08s ease-in-out, -webkit-transform .08s ease-in-out;
    font-size: 1.4rem;
    border-radius: 0.2rem;
    background-color: #eee;
    color: #888D95;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 1rem 1rem;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-width: 30rem;
    padding-right: 1.7rem;
    position: relative;
    -webkit-transform: translateX(-1rem);
    -ms-transform: translateX(-1rem);
    transform: translateX(-1rem);
    opacity: 0;
}

.c-notification-card__label {
    position: relative;
    top: 0.1rem;
    margin-right: 2.5rem;
    padding-left: 1rem;
}

.c-notification-card__time {
    position: relative;
    top: 0.1rem;
    opacity: .7;
}

.c-notification-card__btn {
    position: absolute;
    right: 1rem;
    background-color: transparent;
    border: 0;
    padding: 1px;
    top: 50%;
    width: 20px;
    padding-right: 95%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #888D95;
    cursor: pointer;
}

.c-notification-card__btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.c-notification-card__btn:hover {
    outline: none !important;
    box-shadow: none !important;
}

.c-notification-card__btn .o-icon {
    fill: red;
}

.c-notification-card.is-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.c-notification-card--primary {
    background-color: #DDDDF8;
    color: #0b7a26;
}

.c-notification-card--primary .c-notification-card__btn {
    color: #0b7a26;
}

.c-notification-card--error {
    background-color: #FFD6D4;
    color: red;
}

.c-notification-card--error .c-notification-card__btn {
    color: red;
}